import React,{ useEffect, useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {useLocation,useHistory,useParams} from 'react-router-dom'
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import * as API from '../API'
import defaultCardImage from "../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../images/support-icon.svg";
import ShieldIconImage from "../images/shield-icon.svg";
import CustomizeIconImage from "../images/customize-icon.svg";
import FastIconImage from "../images/fast-icon.svg";
import ReliableIconImage from "../images/reliable-icon.svg";
import SimpleIconImage from "../images/simple-icon.svg";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 w-full` }
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const styleLoading = {
  position: 'absolute',
  display:'flex',
  top: '50%',
  left: '50%',
  justifyContent:'center',
  flexDirection:'column',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px double #C5C5C5',
  boxShadow: 50,
  p: 3,
};

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const {id} = useParams()
  const location = useLocation()
  const path = location.pathname.split("/")[1]
  const [models, setModels] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoding, setIsLoading] = useState(false);

  const handleOpen = (id) => {
    if(path==="files"){
      setIsLoading(true)
      setOpen(true)
      getFiles(id)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setFiles([])
  }

  useEffect(() => {
    getBrand()
    console.log(models)
    return(()=>{
      setModels([]);
    })
  }, []);
  const getBrand = async () => {
    if(path === "tool"){
      const {data} = await API.getToolModel(id)
      setModels(data)
    }else if(path === "files"){
      const {data} = await API.getFileModel(id)
      setModels(data)
    }
  }

  const getFiles = async (id) => {
    const {data} = await API.getFileList(id)
    setFiles(data)
    console.log(data)
    setIsLoading(false)
  }

  const history = useHistory()
  const manageRouting = (id) => {
    if (path === "files"){
      // history.push(`/files/${id}`)
    }
  }


  return (
    <Container>
      <ThreeColumnContainer>
        {
          path === "tool" ?
              <Heading>IKO Tool <span tw="text-primary-500">Supported</span> Models</Heading>
              :
              <Heading>Available <span tw="text-primary-500">Files</span> For Models To Download</Heading>
        }
        {models.map((brand, i) => (
          <Column key={i}>
            <Card onClick={()=>handleOpen(brand.model_id)}>
              <span className="imageContainer">
                <img src={CustomizeIconImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{brand.model_name}</span>
              </span>
            </Card>
          </Column>
        ))}
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          {
            isLoding ?
                <Box sx={styleLoading}>
                  <CircularProgress style={{alignSelf:'center'}}/>
                </Box>
                :
                <Box sx={styleLoading}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{alignSelf:'center'}}>
                    Available Files
                  </Typography>
                  <hr/>
                  {
                    !files.length ?
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          No Files Found..!!
                        </Typography>
                        :
                        files.map((file)=>{
                          return(
                              <>
                                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                                  File Name : {file.file_name}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                                  File Type : {file.file_type}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                                  File Version : {file.file_version}
                                </Typography>
                                <hr/>
                              </>
                          )
                        })

                  }

                </Box>
          }
        </Modal>
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
