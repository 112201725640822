import axios from 'axios'

const API = axios.create({baseURL:'https://admin.filesgsm.org/api'})
// const API = axios.create({baseURL:'http://localhost/backend/api'})

export const getFileBrands = async () => {
    return await API.get(`/dl-brand`)
}

export const getToolBrands = async () => {
    return await API.get(`/brands`)
}

export const getToolModel = async (id) => {
    return await API.get(`/models/${id}`)
}
export const getFileModel = async (id) => {
    return await API.get(`/dl-model/${id}`)
}
export const getFileList = async (id) => {
    return await API.get(`/dl-links/${id}`)
}