import {NavLink, NavLinks} from "../components/headers/light";
import React from "react";

export const navLinks = [
    <NavLinks key={1}>

        <NavLink href="/about-us">
            About Us
        </NavLink>
        <NavLink href="/tool">
            FilesGSM Tool
        </NavLink>
        <NavLink href="/files">
            Unlocking & Fix File
        </NavLink>
        <NavLink href="/contact-us">
            Contact Us
        </NavLink>
        <NavLink  target="_blank" href="https://reseller.filesgsm.org/">
            Reseller Panel
        </NavLink>
        <NavLink  target="_blank" href="https://user.filesgsm.org/">
            User Panel
        </NavLink>
    </NavLinks>,
];